@import url(../../node_modules/@syncfusion/ej2-material-theme/styles/customized/material.css);
#root {
  height: 100%;
  width: 100%;
  background-color: #132433; }

a.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
  background-color: #1890ff; }

a.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
  border-radius: 4px; }

.ant-notification,
.ant-message {
  z-index: 1040; }

/******  Form Styling ******/
.ant-form-item-label {
  line-height: 18px; }

.ant-form-item-label label {
  color: #212121;
  font-size: 13px !important; }

.ant-form-explain {
  font-size: 11px; }

.ant-form-item {
  margin-bottom: 4px; }

.ant-radio-group-solid {
  display: flex;
  padding: 2px 0; }
  .ant-radio-group-solid .ant-radio-button-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center; }

/************************/
.ant-table-tbody .actions {
  text-align: center; }

.ant-collapse-content {
  border: solid 1px #cde7ff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .ant-collapse-content .ant-collapse-content-box {
    padding: 16px !important; }

.hide-expand-icon .ant-table-row-expand-icon-cell span {
  visibility: hidden; }

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5; }

.tagDashboard {
  background: #f0f2f5; }

.ellipsis {
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden; }

.dropdown {
  z-index: 1000;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  max-height: 260px;
  overflow: auto;
  background-color: #fff; }

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px; }

.dropdown .item.fontsize-item,
.dropdown .item.fontsize-item .text {
  min-width: unset; }

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain; }

.dropdown .item:first-child {
  margin-top: 8px; }

.dropdown .item:last-child {
  margin-bottom: 8px; }

.dropdown .item:hover {
  background-color: #eee; }

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px; }

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px; }

.RichTextEditor__tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0; }

.TableCellResizer__resizer {
  position: absolute; }

.RichTextEditor__tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: #3c84f4;
  height: 100%;
  top: 0; }

.TableCellResizer__resizer TableCellResizer__ui {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0; }

.resizer-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000; }

@media screen and (max-width: 1100px) {
  .dropdown-button-text {
    display: none !important; }
  .font-size .dropdown-button-text {
    display: flex !important; }
  .code-language .dropdown-button-text {
    display: flex !important; } }
